<template :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" >
  <div class="order-form container my-5">
    <form @submit.prevent="submitForm" class="bg-white p-5 rounded shadow">
      <div class="form-group mb-4">
        <label for="name" class="form-label">الاسم</label>
        <input
          type="text"
          v-model="form.name"
          id="name"
          class="form-control"
          required
        />
      </div>

      <div class="form-group mb-4">
        <label for="phone" class="form-label">الهاتف</label>
        <div class="input-group">
          <span class="input-group-text">
            <img :src="flagUrl" alt="flag" v-if="flagUrl" class="flag-icon" />
            <i v-else class="fas fa-flag"></i>
          </span>
          <input
            type="text"
            v-model="form.phone"
            id="phone"
            class="form-control"
            :class="{ 'is-invalid': !isValidPhone }"
            @input="validatePhone"
            placeholder="مثلاً: 551234567"
            required
          />
        </div>
        <div v-if="!isValidPhone" class="invalid-feedback">
          من فضلك ادخل رقم هاتف سعودي صالح.
        </div>
      </div>

      <div class="form-group mb-4">
        <label for="city" class="form-label">المدينة</label>
        <select
          v-model="form.city"
          id="city"
          class="form-select"
          @change="loadNeighborhoods"
        >
          <option value="" disabled>اختر المدينة</option>
          <option
            v-for="city in cities"
            :key="city"
            :value="city.area_with_hoods.id"
          >
            {{ city.area_with_hoods.name }}
          </option>
        </select>
      </div>

      <div class="form-group mb-4">
        <label for="neighborhood" class="form-label">الحي</label>
        <select
          v-model="form.neighborhood"
          id="neighborhood"
          class="form-select"
        >
          <option value="" disabled>اختر الحي</option>
          <option v-for="hood in neighborhoods" :key="hood" :value="hood.id">
            {{ hood.name }}
          </option>
        </select>
      </div>

      <button type="submit" class="btn btn-primary w-100">إرسال الطلب</button>
    </form>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      form: {
        name: "",
        phone: "",
        city: "",
        neighborhood: "",
      },
      cities: [],
      neighborhoods: [],
      allNeighborhoods: {
        "المدينة المنورة": ["حي العوالي", "حي القصواء", "حي شوران"],
        الرياض: ["حي العليا", "حي النخيل", "حي الملز"],
        جدة: ["حي الشاطئ", "حي المرجان", "حي البساتين"],
      },
      isValidPhone: true,
      flagUrl: "",
    };
  },

  methods: {
    getAreas() {
      this.http.get("areas/with-hood").then((res) => {
        this.cities = res.data;

        console.log(this.cities);
      });
    },
    validatePhone() {
      const phonePattern = /^5\d{8}$/;
      this.isValidPhone = phonePattern.test(this.form.phone);
      this.updateFlag();
    },
    async updateFlag() {
      //   const countryCode = this.form.phone.slice(0, 3);
      const url = `https://flagcdn.com/48x36/sa.png`; // Assuming Saudi Arabia for simplicity
      try {
        await axios.get(url);
        this.flagUrl = url;
      } catch (error) {
        this.flagUrl = "";
      }
    },
    loadNeighborhoods() {
      for (let i = 0; i < this.cities.length; i++) {
        for (let j = 0; j < this.cities[i].area_with_hoods.hoods.length; j++) {
          if (
            this.form.city ==
              this.cities[i].area_with_hoods.hoods[j].parent_id &&
            this.cities[i]?.area_with_hoods.hoods[j]?.name!= null
          ) {
            console.log(this.form.city);

            this.neighborhoods.push(this.cities[i].area_with_hoods.hoods[j]);
          }
        }
      }
      //   this.neighborhoods = this.filter.map((item) => {
      //     var hoods = [];
      //     for (let i = 0; i < item.area_with_hoods.hoods.length; i++) {
      //       if (
      //         item.area_with_hoods.hoods[i].parent_id == item.area_with_hoods.id
      //       && item.area_with_hoods.hoods[i]!=null ) {
      //         hoods.push(item.area_with_hoods.hoods[i]);
      //       }
      //     }
      //     return hoods;
      //   });
      //   console.log(this.neighborhoods);
    },
    submitForm() {
      if (this.isValidPhone) {
        alert("تم إرسال النموذج بنجاح!");
      } else {
        alert("يرجى تصحيح الأخطاء قبل الإرسال.");
      }
    },
  },

  created() {
    this.getAreas();
  },
};
</script>

<style scoped>
@import "~bootstrap/dist/css/bootstrap.min.css";
@import "~@fortawesome/fontawesome-free/css/all.min.css";

body {
  background-color: #f8f9fa;
}

.order-form {
  max-width: 600px;
  margin: 0 auto;
}

.input-group-text {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  background-color: #f1f3f5;
  border: 1px solid #ced4da;
  border-right: 0;
}

.flag-icon {
  width: 24px;
  height: 18px;
}

.form-control,
.form-select {
  border-radius: 0 0.25rem 0.25rem 0;
}

.is-invalid {
  border-color: #dc3545 !important;
}

.invalid-feedback {
  color: #dc3545;
  display: block;
}

.btn-primary {
  background-color: #007bff;
  border-color: #007bff;
  transition: background-color 0.3s, border-color 0.3s;
}

.btn-primary:hover {
  background-color: #0056b3;
  border-color: #004085;
}

.form-label {
  font-weight: bold;
}

.form-control::placeholder {
  color: #6c757d;
}
</style>
